import { ReactNode } from 'react';

import classNames from 'classnames';

import { RecapAsset } from '@/api/trading/types';
import { calcSpreadInPips } from '@/api/utils';
import { LinkAsset } from '@/blocks/components/PlatformLink';
import {
  CellActions,
  CellAsset,
  CellChart,
  CellPct,
  CellPrice,
  CellPriceAndChange,
} from '@/blocks/components/Table';
import buttons from '@/styles/button.module.scss';

export const renderCell = {
  asset: (asset: RecapAsset) => (
    <CellAsset key={'cell-asset-' + asset.name} data-column-name={'asset'} asset={asset} />
  ),
  priceAndChange: (asset: RecapAsset) => (
    <CellPriceAndChange
      data-column-name={'price-and-change'}
      key={'cell-price-and-change-' + asset.name}
      icon={true}
      priceScale={asset.priceScale}
      price={asset.price}
      change={asset.change24h}
    />
  ),
  price: (asset: RecapAsset) => (
    <CellPrice
      data-column-name={'price'}
      priceScale={asset.priceScale}
      icon
      key={'cell-price-' + asset.name}
      price={asset.price}
      negative={(asset.change24h || 0) < 0}
    />
  ),
  high24: (asset: RecapAsset) => (
    <CellPrice
      key={'cell-high24-' + asset.name}
      data-column-name={'high24'}
      priceScale={asset.priceScale}
      price={asset.highPrice24h}
    />
  ),
  low24: (asset: RecapAsset) => (
    <CellPrice
      key={'cell-low24-' + asset.name}
      data-column-name={'low24'}
      priceScale={asset.priceScale}
      price={asset.lowPrice24h}
    />
  ),
  actions: (text: ReactNode, enText?: ReactNode) => (asset: RecapAsset) => {
    return (
      <CellActions key={'cell-actions-' + asset.name} data-column-name={'actions'} data-event-label={enText}>
        <LinkAsset asset={asset} className={classNames(buttons.btn, buttons.btnXXS, buttons.btnOutline)}>
          {text}
        </LinkAsset>
      </CellActions>
    );
  },
  trend: (asset: RecapAsset) => (
    <CellChart
      key={'cell-trend-' + asset.name}
      data-column-name={'trend'}
      chartValues={asset.trend}
      negative={(asset.change24h || 0) < 0}
    />
  ),
  vol24: (asset: RecapAsset) => (
    <CellPrice
      key={'cell-vol24-' + asset.name}
      data-column-name={'vol24'}
      priceScale={asset.qtyScale}
      price={asset.vol24}
    />
  ),
  chg24: (asset: RecapAsset) => (
    <CellPct key={'cell-chg24-' + asset.name} data-column-name={'chg24'} pct={asset.change24h} />
  ),
  sell: (asset: RecapAsset) => (
    <CellPrice
      key={'cell-sell-' + asset.name}
      priceScale={asset.priceScale}
      data-column-name={'sell'}
      price={asset.ask}
    />
  ),
  buy: (asset: RecapAsset) => (
    <CellPrice
      key={'cell-buy-' + asset.name}
      priceScale={asset.priceScale}
      data-column-name={'buy'}
      price={asset.bid}
    />
  ),
  spread: (asset: RecapAsset) => {
    const spreadInPips = calcSpreadInPips(asset.ask, asset.bid, asset.priceScale);
    return (
      <CellPrice
        key={'cell-spread-' + asset.name}
        data-column-name={'spread'}
        price={spreadInPips}
        priceScale={0}
      />
    );
  },
};
