'use client';

import React, { PropsWithChildren } from 'react';

import { useMobileStoreLink } from '@/blocks/components/PlatformLink/useMobileStoreLink';
import { MobileStore } from '@/blocks/components/StoreButton/enums';
import { UrgentPicture } from '@/blocks/components/UrgentPicture';

import appStoreIcon from './apple.svg';
import googlePlayIcon from './google.svg';
import styles from './styles.module.scss';

// TODO make it as a variant for mobile app button (short) with QR wrapper (WithMobileAppQrOnHover)
export function MobileAppQrButton({ store, children }: PropsWithChildren<{ store: MobileStore }>) {
  const { appStore, googlePlay } = useMobileStoreLink();
  return (
    <a
      href={store === MobileStore.GOOGLE_PLAY ? googlePlay : appStore}
      target={'_blank'}
      className={styles.qrCodeButton}
    >
      <UrgentPicture
        src={store === MobileStore.GOOGLE_PLAY ? googlePlayIcon : appStoreIcon}
        alt={'google'}
        height={32}
        width={32}
      />
      {children}
    </a>
  );
}
