import { Optional } from '@/types';
import Image, { ImageProps } from 'next/image';

export function UrgentPicture({
  blurredLoading,
  ...props
}: Optional<ImageProps, 'alt'> & {
  blurredLoading?: boolean;
}) {
  return (
    <Image loading={'eager'} priority alt={''} placeholder={blurredLoading ? 'blur' : undefined} {...props} />
  );
}
