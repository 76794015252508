'use client';

import React from 'react';

import classNames from 'classnames';

import { useMobileStoreLink } from '@/blocks/components/PlatformLink/useMobileStoreLink';
import { MobileStoreLogo } from '@/blocks/components/StoreButton/MobileStoreLogo';
import { MobileStore } from '@/blocks/components/StoreButton/enums';
import { WithClassName } from '@/blocks/components/types';
import { useDeviceInfo } from '@/hooks/useDeviceDetect';
import buttons from '@/styles/button.module.scss';

export function UniversalStoreButton({ className }: WithClassName) {
  const { appStore, googlePlay } = useMobileStoreLink({ location: 'hero' });
  const deviceInfo = useDeviceInfo();

  const cn = classNames(buttons.btn, buttons.btnResponsiveML, buttons.btnPrimary, buttons.btnFlat, className);

  if (!deviceInfo) {
    return null;
  }

  return (
    <a href={deviceInfo?.isIOS ? appStore : googlePlay} className={cn} target={'_blank'}>
      <MobileStoreLogo
        store={deviceInfo?.isIOS ? MobileStore.APP_STORE : MobileStore.GOOGLE_PLAY}
        theme={'white'}
        width={0}
        height={0}
        priority
        loading={'eager'}
      />
    </a>
  );
}
